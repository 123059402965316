/* eslint-disable multiline-ternary */
/* eslint-disable indent */
/* eslint-disable no-unreachable */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as ApplicationActions from '../ApplicationLayout/actions'
import * as DashBoardActions from './actions'
import * as SelectSiteActions from '../SelectSitePage/actions'
import * as ProjectDetailActions from '../ProjectDetailPage/actions'
import * as NotificationActions from '../NotificationSidebar/actions'

import DashBoardTop from '../../components/DashboardTop/Loadable'
import PageWrapper from '../../components/PageWrapper/Loadable'
import SubLeftbarWrapper from '../../components/SubLeftbarWrapper/Loadable'
import DivideLine from '../../components/DivideLine/Loadable'
import ListBoardWrapper from '../../components/ListBoardWrapper/Loadable'
import AreaDetailCard from '../../components/AreaDetailCard/Loadable'
import OverallViolationCountCard from '../../components/OverallViolationCountCard/Loadable'
import ViolationCountGraph from '../../components/ViolationCountGraph/Loadable'
import MapAreaDetailCard from '../../components/MapAreaDetailCard/Loadable'
import ViolationCountRanking from '../../components/ViolationCountRanking/Loadable'
import ViolationCountAreaRanking from '../../components/ViolationCountAreaRanking/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import Dropdown from '../../components/Dropdown/Loadable'
import DatePickerComponent from '../../components/DatePickerComponent/Loadable'
import DateFilterComponent from '../../components/DateFilterComponent/Loadable'
import ViolationCount from '../../components/ViolationCount/Loadable'
import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FRONTEND_ROUTES,
  THAI_LANGUAGE,
  CAMERA_ACTIVE,
  CAMERA_INACTIVE,
  makeFirstLetterCapital
} from '../../utils'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import CollapseIcon from '../../asset/images/button/collapse.svg'
import ExpandIcon from '../../asset/images/button/expand.svg'
import DashboardPageStyled from './styledComponent'
import MESSAGE from './message'
import colors from '../../colors'

const LIST_COLOR_BARS = ['#f16745', '#ffc65d', '#7bc8a4', '#4cc3d9', '#93648d', '#404040']

let timing

export class DashboardPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCompareMode: false,
      areaSortByObject: { text: this.getMessage('default_dropdown'), value: '' },
      lastSyncTime: moment(),
      subLeftBarCollapse: false,
      showAlertNoCameraModal: false,
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    }
    this.abortController = new AbortController()
  }

  async componentDidMount() {
    const projectID = this.props.match.params.projectID
    await this.props.setCurrentProjectCookie(projectID)
    this.loadListSite()
    this.loadPageData()
    this.initialIntervalLoadData()
  }

  componentDidUpdate(prevProps, prevState) {
    this._handleSiteChanged(prevProps)
    this._handleFetchLoadDashboardData(prevProps)
  }

  componentWillUnmount() {
    clearInterval(timing)
  }

  _handleFetchLoadDashboardData(prevProps) {
    if (this._isLoadDashboardSuccess(prevProps)) {
      const data = this.props.dashboardPage.dashboardData
      const amountCamera = data.listAreaDetail.reduce((prev, current) => prev + current.cameraData.length, 0)
      if (amountCamera === 0) {
        this.setShowAlertNoCameraModal()
      }
    }
    if (this._isSyncDashboardSuccess(prevProps)) {
      this.resetSyncTime()
    }
    if (this._isLoadDashboardFail(prevProps)) {
      this.handleLoadDashboardFailure()
    }
    if (this._isSyncDashboardFail(prevProps)) {
      this.handleSyncDashboardFailure()
    }
  }

  _isLoadDashboardSuccess = (prevProps) => {
    return (
      prevProps.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_SUCCESS
    )
  }

  _isLoadDashboardFail = (prevProps) => {
    return (
      prevProps.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_FAILURE
    )
  }

  _isSyncDashboardSuccess = (prevProps) => {
    return (
      prevProps.dashboardPage.syncDashboard.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.dashboardPage.syncDashboard.fetchStatus === FETCH_STATUS_SUCCESS
    )
  }

  _isSyncDashboardFail = (prevProps) => {
    return (
      prevProps.dashboardPage.syncDashboard.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.dashboardPage.syncDashboard.fetchStatus === FETCH_STATUS_FAILURE
    )
  }

  handleSyncDashboardFailure() {
    const statusCode = this.props.dashboardPage.syncDashboard.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  handleLoadDashboardFailure() {
    const statusCode = this.props.dashboardPage.loadDashboard.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  forceLogin = () => {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  setShowAlertNoCameraModal = () => {
    this.setState({
      showAlertNoCameraModal: true
    })
  }

  async _handleSiteChanged(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const projectID = this.props.match.params.projectID
      await this.props.setCurrentProjectCookie(projectID)
      this.loadListSite()
      this.loadPageData()
      this.initialIntervalLoadData()
    }
  }

  initialIntervalLoadData() {
    timing = setInterval(() => {
      this.syncDashboardData()
    }, process.env.INTERVAL_CALL_API_MINUTES * 60 * 1000)
  }

  resetSyncTime() {
    this.setState({
      lastSyncTime: moment()
    })
  }

  loadListSite() {
    this.props.getListSite()
  }

  syncDashboardData() {
    const siteId = this.props.match.params.siteId
    const day = this.getDayRequestAPI()
    const type = this.props.dateFilterType
    this.props.syncDashboard(day, type, siteId)
    this.props.getListNotification(siteId)
  }

  loadPageData() {
    this.abortController.abort()
    this.abortController = new AbortController()
    const siteId = this.props.match.params.siteId
    const day = this.getDayRequestAPI()
    const type = this.props.dateFilterType
    this.props.loadDashboard(day, type, siteId, this.abortController.signal)
  }

  getDayRequestAPI() {
    let day = this.props.date.format('YYYY-MM-DD')
    if (this.props.dateFilterType === 'week') {
      day = moment(this.props.date).startOf('week').format('YYYY-MM-DD')
    }
    return day
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language || THAI_LANGUAGE]
    return msg[property]
  }

  collapseSubLeftBar() {
    this.setState({
      subLeftBarCollapse: true
    })
  }

  expandSubLeftBar() {
    this.setState({
      subLeftBarCollapse: false
    })
  }

  getSubLeftbarTitle() {
    return (
      <div className="left-bar-header text-style" id="lbl-page-title">
        {this.getMessage('dashboard_subleftbar')}
      </div>
    )
  }

  renderCollapseButton() {
    return (
      <div className="collapse-button" onClick={() => this.collapseSubLeftBar()}>
        <img src={CollapseIcon} alt="" />
      </div>
    )
  }

  renderExpandButton() {
    return (
      <div className="expand-button" onClick={() => this.expandSubLeftBar()}>
        <img src={ExpandIcon} alt="" />
      </div>
    )
  }

  getSubLeftbarSubTitle() {
    const { listAreaDetail } = this.props.dashboardPage.dashboardData
    const listCamera = listAreaDetail.reduce((previous, areaDetail) => {
      return [...previous, ...areaDetail.cameraData]
    }, [])
    const amountCamera = listCamera.length
    const activeAndInActiveCamera = listCamera.filter(
      (camera) => camera.camera_status.toUpperCase() === CAMERA_ACTIVE || camera.camera_status.toUpperCase() === CAMERA_INACTIVE
    ).length
    return (
      <div className="left-bar-sub-header text-style" id="lbl-page-cameras">
        {`${this.getMessage('camera_subleftbar')} ${activeAndInActiveCamera}/${amountCamera} ${this.getMessage('active_subleftbar')}`}
      </div>
    )
  }

  getSubLeftbarFooter() {
    const { syncDashboard } = this.props.dashboardPage
    let syncText = `${this.getMessage('last_sync_subleftbar')} ${this.state.lastSyncTime.format('HH:mm:ss')}`
    if (syncDashboard.fetchStatus === FETCH_STATUS_REQUEST) {
      syncText = `${this.getMessage('now_sync_subleftbar')}...`
    }
    const latestYear = new Date().getFullYear()
    return (
      <div className="left-bar-footer">
        <div className="message text-style" id="lbl-info-sync">
          {this.getMessage('sync_info_subleftbar')}
        </div>
        <div className="sync-container text-style" id="lbl-sync">
          {syncText}
        </div>
        <div className="footer-zone">
          <DivideLine className="footer-line" />
          <div className="footer-msg text-style">
            © {latestYear} Sertis Co., Ltd. <br />
            {this.getMessage('subleftbar_footer')}
          </div>
        </div>
      </div>
    )
  }

  getSubLeftbar() {
    if (this.state.subLeftBarCollapse) {
      return <SubLeftbarWrapper className="collapse">{this.renderExpandButton()}</SubLeftbarWrapper>
    } else {
      return (
        <SubLeftbarWrapper className="dashboard-left-bar">
          {this.getSubLeftbarTitle()}
          {this.renderCollapseButton()}
          {this.getSubLeftbarSubTitle()}
          <DivideLine className="leftbar-line" />
          {this.getSubLeftbarFooter()}
        </SubLeftbarWrapper>
      )
    }
  }

  getDashboardTopChildrenOneAreaLoading() {
    return (
      <>
        <MapAreaDetailCard isLoading={true} listCamera={[]} style={{ margin: 0 }} />
        <ViolationCountRanking isLoading={true} data={[]} />
      </>
    )
  }

  getDashboardTopChildrenOneAreaLoadSuccess() {
    const { listAreaDetail } = this.props.dashboardPage.dashboardData
    const isLoadingStatus = this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST
    return (
      <>
        <MapAreaDetailCard
          listCamera={listAreaDetail[0].cameraData}
          isLoading={isLoadingStatus}
          detail={listAreaDetail[0].areaDetail}
          onCameraClicked={(cameraData) => this.handleCameraClicked(cameraData)}
          language={this.props.currentUser?.language || THAI_LANGUAGE}
          dateTimeFilterBy={this.props.dateFilterType}
        />
        <ViolationCountRanking
          title={this.getMessage('violation_count_ranking_header')}
          data={listAreaDetail[0].areaDetail.cameraViolationRanking}
          isLoading={isLoadingStatus}
          language={this.props.currentUser?.language || THAI_LANGUAGE}
          className="dashboard-violation-count"
        />
      </>
    )
  }

  getViolationCountGraphLoading() {
    return <ViolationCountGraph graphData={{}} listGraphDetail={[]} isLoading={true} language={this.props.currentUser?.language || THAI_LANGUAGE} />
  }

  getViolationCountGraphLoadSuccess() {
    const { listAreaDetail } = this.props.dashboardPage.dashboardData
    return (
      <ViolationCountGraph
        title={this.getMessage('violation_count_bd_header')}
        graphData={listAreaDetail[0].graphData}
        listGraphDetail={this.getListGraphDetail(listAreaDetail[0].graphData)}
        isLoading={this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST}
        language={this.props.currentUser?.language || THAI_LANGUAGE}
      />
    )
  }

  getDashboardTopChildren() {
    const { dashboardTopGraphData, violationCountRankData, listAreaDetail } = this.props.dashboardPage.dashboardData
    return (
      <>
        <ViolationCount
          title={this.getMessage('violation_count_header')}
          data={dashboardTopGraphData}
          listBars={this.getListGraphDetail(dashboardTopGraphData)}
          xLabel={this.getMessage('time_label')}
          yLabel={this.getMessage('count_label')}
          style={{ marginRight: 20 }}
          isLoading={this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST}
        />
        <ViolationCountAreaRanking
          title={this.getMessage('violation_count_ranking_header')}
          data={violationCountRankData}
          isLoading={this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST}
          language={this.props.currentUser?.language || THAI_LANGUAGE}
          oneArea={listAreaDetail.length === 1}
        />
      </>
    )
  }

  getDashboardTopChildrenOneArea() {
    const { dashboardTopGraphData, listAreaDetail } = this.props.dashboardPage.dashboardData

    return (
      <>
        <OverallViolationCountCard
          title={this.getMessage('ova_violation_count_header')}
          data={dashboardTopGraphData}
          listBars={this.getListGraphDetail(dashboardTopGraphData)}
          xLabel={this.getMessage('time_label')}
          yLabel={this.getMessage('count_label')}
          style={{ marginBottom: '30px' }}
          isLoading={this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST}
        />
        <div className="area-detail-and-count-rank-wrapper">
          {listAreaDetail.length === 0 ? this.getDashboardTopChildrenOneAreaLoading() : this.getDashboardTopChildrenOneAreaLoadSuccess()}
        </div>
        <div className="violation-count-graph">
          {listAreaDetail.length === 0 ? this.getDashboardTopChildrenOneAreaLoading() : this.getViolationCountGraphLoadSuccess()}
        </div>
      </>
    )
  }

  async handleFilterChanged(value) {
    await this.props.updateDateFilter(value)
    this.loadPageData()
  }

  async handleDateChanged(date) {
    await this.props.updateDatePicker(date)
    this.loadPageData()
  }

  getDateComponent() {
    return (
      <div className="date-time-container">
        <div className="date-picker-container">
          <DatePickerComponent value={this.props.date} onDateChange={(date) => this.handleDateChanged(date)} type={this.props.dateFilterType} />
        </div>
        <div className="date-filter-container">
          <DateFilterComponent
            value={this.props.dateFilterType}
            onFilterChanged={(value) => this.handleFilterChanged(value)}
            language={this.props.currentUser?.language || THAI_LANGUAGE}
          />
        </div>
      </div>
    )
  }

  handleCameraClicked(cameraData) {
    const projectID = this.props.match.params.projectID
    const siteId = this.props.match.params.siteId
    this.props.history.push(`${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${siteId}/violation/${cameraData.camera_id}`)
  }

  sortListAreaDetail(listAreaDetail) {
    const sortedList = listAreaDetail.sort((a, b) => {
      if (this.state.areaSortByObject.value === 'desc') return b.areaDetail.totalViolationCount - a.areaDetail.totalViolationCount
      else if (this.state.areaSortByObject.value === 'asc') return a.areaDetail.totalViolationCount - b.areaDetail.totalViolationCount
      else {
        return a.areaDetail.id - b.areaDetail.id
      }
    })
    return sortedList
  }

  getListAreaCards() {
    const { listAreaDetail } = this.props.dashboardPage.dashboardData
    let output = <AreaDetailCard isLoading={true} detail={{}} graphData={[]} listGraphDetail={[]} listCamera={[]} />
    if (this.props.dashboardPage.loadDashboard.fetchStatus !== FETCH_STATUS_REQUEST) {
      const sortedListAreaDetail = this.sortListAreaDetail(listAreaDetail)
      output = sortedListAreaDetail.map((data) => {
        return (
          <AreaDetailCard
            key={data.areaDetail.areaName}
            listCamera={data.cameraData}
            detail={data.areaDetail}
            graphData={data.graphData}
            listGraphDetail={this.getListGraphDetail(data.graphData)}
            onCameraClicked={(cameraData) => this.handleCameraClicked(cameraData)}
            language={this.props.currentUser?.language || THAI_LANGUAGE}
          />
        )
      })
    }
    return output
  }

  getListGraphDetail(arrayData) {
    if (arrayData.length === 0) {
      return []
    }
    const sampleData = arrayData.reduce((prev, current) => {
      return Object.assign({}, prev, current)
    }, {})
    delete sampleData['label']
    const listGraphDetail = Object.keys(sampleData).map((key, index) => {
      return {
        name: key,
        color: LIST_COLOR_BARS[index]
      }
    })
    return listGraphDetail
  }

  handleSortAreaDetailChanged(data) {
    this.setState({
      areaSortByObject: data
    })
  }

  getSortFilterAreaDetail() {
    const areaDetailOptions = [
      { text: this.getMessage('default_dropdown'), value: '' },
      { text: this.getMessage('area_dropdown'), value: 'area' },
      { text: this.getMessage('total_violation_high'), value: 'desc' },
      { text: this.getMessage('total_violation_low'), value: 'asc' }
    ]
    return (
      <Dropdown
        options={areaDetailOptions}
        label={this.getMessage('sort_by')}
        onChange={(data) => this.handleSortAreaDetailChanged(data)}
        value={this.state.areaSortByObject}
        labelPosition="left"
        buttonColor={colors.jadeGreen}
        language={this.props.currentUser?.language || THAI_LANGUAGE}
      />
    )
  }

  getSiteTitle() {
    let output = ''
    if (this.props.dashboardPage.loadDashboard.fetchStatus !== FETCH_STATUS_REQUEST && this.props.dashboardPage.siteDetailData) {
      output = makeFirstLetterCapital(this.props.dashboardPage.siteDetailData.name)
    }
    return output
  }

  getAlertNoCameraModal() {
    let output = null
    if (this.state.showAlertNoCameraModal) {
      output = (
        <ModalNotification
          className={'no-camera-modal'}
          title={this.getMessage('no_camera_alert_dialog_title')}
          message={this.getMessage('no_camera_alert_dialog_message')}
          onPrimaryButtonClick={() => this.props.history.push(FRONTEND_ROUTES.selectSiteRoute)}
          primaryButtonText="Go back"
        />
      )
    }
    return output
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  getDashboardTopOnlyLoading() {
    const { dashboardTopGraphData } = this.props.dashboardPage.dashboardData

    return (
      <>
        <OverallViolationCountCard
          title={this.getMessage('ova_violation_count_header')}
          data={dashboardTopGraphData}
          listBars={this.getListGraphDetail(dashboardTopGraphData)}
          xLabel={this.getMessage('time_label')}
          yLabel={this.getMessage('count_label')}
          style={{ marginBottom: '30px' }}
          isLoading={this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST}
        />
        <div className="area-detail-and-count-rank-wrapper">{this.getDashboardTopChildrenOneAreaLoading()}</div>
        <div className="violation-count-graph">{this.getDashboardTopChildrenOneAreaLoading()}</div>
      </>
    )
  }

  getListAreaContent() {
    return (
      <ListBoardWrapper title={this.getMessage('area_detail_header')} titleID="lbl-area-details" filter={this.getSortFilterAreaDetail()}>
        {this.getListAreaCards()}
      </ListBoardWrapper>
    )
  }

  getDashboard() {
    let output = null
    let listAreaOutput = null
    let dashboardAlignment = 'vertical'
    if (this.props.dashboardPage.loadDashboard.fetchStatus === FETCH_STATUS_REQUEST) {
      output = this.getDashboardTopOnlyLoading()
    } else if (this.props.dashboardPage.dashboardData.listAreaDetail.length === 1) {
      output = this.getDashboardTopChildrenOneArea()
    } else {
      dashboardAlignment = 'horizontal'
      output = this.getDashboardTopChildren()
      listAreaOutput = this.getListAreaContent()
    }
    return (
      <PageWrapper subLeftbar={this.getSubLeftbar()} subleftBarCollapse={this.state.subLeftBarCollapse}>
        <ListBoardWrapper title={this.getSiteTitle()} titleID="lbl-page-name" filter={this.getDateComponent()}>
          <DashBoardTop alignMent={dashboardAlignment}>{output}</DashBoardTop>
        </ListBoardWrapper>
        {listAreaOutput}
      </PageWrapper>
    )
  }

  render() {
    return (
      <DashboardPageStyled>
        {this.getDashboard()}
        {this.getAlertNoCameraModal()}
        {this.getFailureModal()}
      </DashboardPageStyled>
    )
  }
}

DashboardPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dashboardPage: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentProjectCookie: PropTypes.func.isRequired,
  getListSite: PropTypes.func.isRequired,
  syncDashboard: PropTypes.func.isRequired,
  getListNotification: PropTypes.func.isRequired,
  loadDashboard: PropTypes.func.isRequired,
  updateDateFilter: PropTypes.func.isRequired
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    dashboardPage: state.dashboardPage,
    currentUser: state.loginPage.loginAPI.userData,
    date: state.applicationPage.date,
    dateFilterType: state.applicationPage.dateFilterType,
    applicationPage: state.applicationPage
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    Object.assign({}, ApplicationActions, DashBoardActions, SelectSiteActions, ProjectDetailActions, NotificationActions),
    dispatch
  )
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(DashboardPage))
