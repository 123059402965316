const MESSAGE = {
  EN: {
    dashboard_subleftbar: 'Dashboard',
    camera_subleftbar: 'Camera',
    active_subleftbar: 'Active',
    all_camera_subleftbar: 'All Camera',
    select_and_compare_subleftbar: 'Select and compare data ranges',
    compare_button_subleftbar: 'Compare',
    sync_info_subleftbar: 'Automatically sync with system when connected to internet.',
    last_sync_subleftbar: 'Last Sync: Today',
    now_sync_subleftbar: 'Now Syncing',
    subleftbar_footer: 'Best viewed in latest 2 versions of Google Chrome.',
    time_label: 'Time',
    count_label: 'Count',
    ova_violation_count_header: 'OVERALL VIOLATION COUNT',
    violation_count_bd_header: 'VIOLATION COUNT BREAKDOWN',
    violation_count_header: 'VIOLATION COUNT',
    violation_count_ranking_header: 'VIOLATION COUNT RANKING',
    sort_by: 'Sort by',
    default_dropdown: '-- Select Sort By --',
    area_dropdown: 'Area',
    total_violation_high: 'Total Violation - High',
    total_violation_low: 'Total Violation - Low',
    area_detail_header: 'Area Details',
    no_camera_alert_dialog_title: 'No Camera',
    no_camera_alert_dialog_message: 'There is no camera in this site.',
    go_back_btn: 'Go Back',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    dashboard_subleftbar: 'แดชบอร์ด',
    camera_subleftbar: 'กล้อง',
    active_subleftbar: 'ออนไลน์',
    all_camera_subleftbar: 'กล้องทั้งหมด',
    select_and_compare_subleftbar: 'เลือกและเปรียบเทียบชุดของข้อมูล',
    compare_button_subleftbar: 'เปรียบเทียบ',
    sync_info_subleftbar: 'ซิงค์อัตโนมัติกับระบบเมื่อเชื่อมต่อกับอินเทอร์เน็ต',
    last_sync_subleftbar: 'เชื่อมต่อล่าสุด วันนี้',
    now_sync_subleftbar: 'กำลังซิงค์',
    subleftbar_footer: 'แสดงผลได้ดีที่สุดใน 2 เวอร์ชันล่าสุดของ Google Chrome',
    time_label: 'เวลา',
    count_label: 'จำนวน',
    ova_violation_count_header: 'จำนวนการฝ่าฝืนโดยรวม',
    violation_count_bd_header: 'จำนวนการฝ่าฝืนของแต่ละกล้อง',
    violation_count_header: 'จำนวนการฝ่าฝืน',
    violation_count_ranking_header: 'อันดับจำนวนครั้งในการฝ่าฝืน',
    sort_by: 'จัดเรียงตาม',
    default_dropdown: '-- เลือกจัดเรียงตาม --',
    area_dropdown: 'พื้นที่',
    total_violation_high: 'จำนวนการฝ่าฝืนมากที่สุด',
    total_violation_low: 'จำนวนการฝ่าฝืนน้อยที่สุด',
    area_detail_header: 'รายละเอียดพื้นที่',
    no_camera_alert_dialog_title: 'ไม่มีกล้อง',
    no_camera_alert_dialog_message: 'ไม่มีกล้องในไซต์นี้',
    go_back_btn: 'กลับ',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}

export default MESSAGE
