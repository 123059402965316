import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .date-time-container {
    display: flex;
    align-items: center;
    .date-picker-container {
      margin-right: 20px;
    }
  }
  .area-detail-and-count-rank-wrapper {
    display: flex;
    margin-bottom: 30px;
    &:first-child {
      margin-right: 10px;
    }
    .dashboard-violation-count {
      margin-left: 20px;
      background-color: white;
    }
  }
`
